import React, { useState } from 'react';
import axios from 'axios';
import QuickLinks from './Quicklinks';

const Footer = () => {
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [subscribed, setSubscribed] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isValidEmail) {
            console.log("Invalid Email");
            return; // Prevent form submission if email is invalid
        }

        try {
            const response = await axios.post('https://mpsglobal.online/backend/index.php', { email });//
            console.log(response.data); // Handle success or error messagess
            setSubscribed(true);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const validateEmail = (input) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(regex.test(input));
    };

    const scrollToTop = () => {
        window.scrollTo({
            top:0,
            behavior: 'smooth'
        });
    };

    return (
        <div>
            <footer className="site-footer section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12 cc">
                            <h4 className="mb-4 me-5 cc">Maa Pranaam Suvidha Limited</h4>
                        </div>

                        <div className="col-lg-3 col-md-7 col-xs-12 tooplate-mt30">
                            <h6 className="text-white mb-lg-4 mb-3">Location</h6>
                            <p>KPCT MALL, Office No. B-3, near Vishal Mega Mart, Fatima Nagar, Wanowrie, Pune, Maharashtra</p>
                            <a href="https://www.google.com/maps/place/KPCT+mall/@18.5033095,73.8982207,17z/data=!3m1!5s0x3bc2c04b28579691:0x69352c33faa644e7!4m6!3m5!1s0x3bc2c1c5f0000001:0xcb943e93a77ea76c!8m2!3d18.5033044!4d73.9007956!16s%2Fg%2F11bw72rj7x?entry=ttu" className="custom-btn btn btn-dark mt-2">Directions</a>
                        </div>

                        <div className="col-lg-3 col-md-5 col-xs-12 tooplate-mt30">
                            <h6 className="text-white mb-lg-4 mb-3">Office Hours</h6>
                            <p className="mb-2">Monday - Saturday</p>
                            <p>09:30 AM - 06:30 PM</p>
                            <p>Tel: <a href="tel: 010-02-0340" className="tel-link">+91 9529059822</a></p>
                        </div>

                        <div className="col-lg-3 col-md-6 col-xs-12 tooplate-mt30">
                            <h6 className="text-white mb-lg-4 mb-3">Social</h6>
                            <ul className="list-unstyled d-flex flex-wrap">
                                <li className="mb-3 me-3">
                                    <a href="#" className="social-icon-link bi bi-facebook fs-3"></a>
                                </li>
                                <li className="mb-3 me-3">
                                    <a href="#" className="social-icon-link bi bi-linkedin fs-3"></a>
                                </li>
                                <li className="mb-3 me-3">
                                    <a href="#" className="social-icon-link bi bi-twitter fs-3"></a>
                                </li>
                                <li className="mb-3 me-3">
                                    <a href="#" className="social-icon-link bi bi-youtube fs-3"></a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 col-xs-12 tooplate-mt30">
                            <h6 className="text-white mb-lg-4 mb-3">Quick Links</h6>
                            <QuickLinks />
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-12 d-flex flex-column flex-md-row align-items-center justify-content-between text-center footer-content">
                            <div className="copyright-container">
                                <p className="copyright-text">Copyright © 2024 Maa Pranaam Suvidha Limited.</p>
                                <div className='t_p'>
                                    <a href="/#/terms_condition" onClick={scrollToTop}>Terms of Use</a>&nbsp;&nbsp; | &nbsp;&nbsp;
                                    <a href="/#/privacy" onClick={scrollToTop}>Privacy & Policy</a>
                                </div>
                            </div>
                            <div className="subscription-container mt-3 mt-md-0 d-flex flex-column align-items-center">
                                {!subscribed ? (
                                    <form onSubmit={handleSubmit} className="subscription-form d-flex flex-column flex-md-row align-items-center">
                                        <div className="d-flex align-items-center mb-2">
                                            <input
                                                type="email"
                                                className={`form-control ${isValidEmail ? '' : 'is-invalid'}`}
                                                placeholder="Enter your email"
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                    validateEmail(e.target.value);
                                                }}
                                                style={{
                                                    width: '250px', // Fixed width
                                                    height: '35px', // Fixed height
                                                    transition: 'width 0.3s ease-in-out'
                                                }}
                                            />
                                            <button className="btn btn-primary ms-2" style={{ height: '35px' }}>Subscribe</button>
                                        </div>
                                        {!isValidEmail && (
                                            <div className="invalid-feedback text-start">Please enter a valid email address.</div>
                                        )}
                                    </form>
                                ) : (
                                    <div className="text-success">
                                        <h4 style={{ color: '#dc3545' }}>Thanks for Subscribing!</h4>
                                    </div>
                                )}
                            </div>
                  
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer